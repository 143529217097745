<template>
  <div class="home-page-container">
    <h2 class="second-colour">Beginner Binance step by step guides</h2>

    <!-- Using <li> so that each line has a bullet point -->

    <ul>
      <!-- ============================== -->
      <!-- HOW TO OPEN A BINANCE ACCOUNT -->
      <li>
        <router-link to="/how-to-open-a-binance-account"
          >How to open a Binance account</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- HOW TO ACTIVATE BINANCE FIAT SERVICE -->
      <li>
        <router-link to="/how-to-activate-binance-fiat-service"
          >How To Activate Binance Fiat Service</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- How To Buy Bitcoin On Binance With Bank Transfer -->
      <li>
        <router-link to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
          >How To Buy Bitcoin On Binance With Bank Transfer</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- HOW TO FUND A BINANCE ACCOUNT -->
      <li>
        <router-link to="/how-to-fund-and-buy-bitcoin-with-a-binance-account"
          >How to buy bitcoin on binance with debit card / credit
          card</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- HOW TO WITHDRAW MONEY -->
      <li>
        <router-link
          to="/how-do-i-withdraw-money-from-binance-to-my-bank-account-website-version"
          >How do I withdraw money from binance to my bank account</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- How to transfer from binance to another wallet address -->
      <li>
        <router-link
          to="/how-to-transfer-from-binance-to-another-wallet-address"
          >How to transfer from binance to another wallet address</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- HowToTransferCryptoFromTrustWalletToBinance -->
      <li>
        <router-link to="/how-to-transfer-crypto-from-trust-wallet-to-binance"
          >How to transfer crypto from Trust Wallet to Binance</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <h2 class="second-colour">Beginner articles</h2>

      <!-- ============================== -->
      <!-- How to invest in bitcoin for beginners -->
      <li>
        <!-- Named routes: https://router.vuejs.org/guide/essentials/named-routes.html -->
        <router-link :to="{ name: 'HowToInvestInBitcoinForBeginners' }">
          How to invest in bitcoin for beginners / dummies
        </router-link>
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- WHAT IS CTYPTO -->
      <li>
        <router-link to="/what-is-cryptocurrency-and-how-does-it-work"
          >What is cryptocurrency and how does it work</router-link
        >
      </li>
      <br />
      <br />
      <!-- ============================== -->

      <!-- ============================== -->
      <!-- 5 reasons why people invest in cryptocurrencies -->
      <li>
        <router-link to="/5-reasons-why-people-invest-in-crypto-currencies"
          >5 reasons why people invest in cryptocurrencies</router-link
        >
      </li>
      <br />
      <br />

      <!-- ============================== -->
      <!-- HowToBuyTruthGptCryptoCoin -->
      <li>
        <router-link :to="{ name: 'HowToBuyTruthGptCryptoCoin' }">
          How to buy Truth GPT crypto coin</router-link
        >
      </li>
      <br />
      <br />

      <!-- ============================== -->
      <!-- PEPEAndSPONGEExplained -->
      <li>
        <router-link :to="{ name: 'PEPEAndSPONGEExplained' }">
          What is the Latest Meme Coin Craze? $PEPE and $SPONGE
          Explained</router-link
        >
      </li>

      <br />
      <br />
      <!-- ============================== -->
      <!-- BestCryptoExchanges -->
      <li>
        <router-link :to="{ name: 'BestCryptoExchanges' }">
          Best Crypto Exchanges For Beginners</router-link
        >
      </li>
    </ul>

    <br />
    <br />

    <!-- ============================== -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home'
  //   components: {
  //     HelloWorld
  //   }
};
</script>
